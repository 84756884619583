var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-container',_vm._b({attrs:{"title":"Consent Forms","id":_vm.id,"provider":_vm.provider,"crud":_vm.crud,"item":_vm.item,"routeAfterSaveAndClose":function (item) { return ({ name: 'ConsentFormList' }); },"routeAfterSave":function (item) { return ({ name: 'ConsentForm', params: { id: item.id } }); }},on:{"update:item":function($event){_vm.item=$event}}},'form-container',Object.assign({}, _vm.$attrs, _vm.$props),false),[(_vm.item)?_c('div',{},[_c('div',{staticClass:"row q-col-gutter-sm items-center"},[_c('div',{staticClass:"col-12 col-md-6 col-lg-3"},[_c('q-input',{attrs:{"filled":"","label":"Name","rules":[_vm.$rules.required]},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})],1),_c('div',{staticClass:"col-12 col-md-6 col-lg-3"},[_c('entity-select',{attrs:{"filled":"","provider":_vm.$service.providers.questionnaires,"value":_vm.item.questionnaireId,"optionLabel":function (i) { return (i ? i.name : ''); },"label":"Questionnaire","rules":[_vm.$rules.required]},on:{"update:value":function($event){return _vm.$set(_vm.item, "questionnaireId", $event)}},model:{value:(_vm.item.questionnaire),callback:function ($$v) {_vm.$set(_vm.item, "questionnaire", $$v)},expression:"item.questionnaire"}})],1),_c('div',{staticClass:"col-12"},[_c('sub-array-form',{attrs:{"array":_vm.files,"generator":function (id) { return ({
              file: {
                id: id,
                fileType: 'General',
                name: '',
                base64Contents: null,
              },
            }); },"validateObject":_vm.validate},on:{"update:array":function($event){_vm.files=$event}},scopedSlots:_vm._u([{key:"form",fn:function(ref){
            var item = ref.item;
return [_c('file-input',{attrs:{"filled":"","label":"File"},scopedSlots:_vm._u([(item.file)?{key:"append",fn:function(){return [_c('q-btn',{attrs:{"round":"","dense":"","flat":"","icon":"delete"},on:{"click":function($event){item.file = null}}})]},proxy:true}:null],null,true),model:{value:(item.file),callback:function ($$v) {_vm.$set(item, "file", $$v)},expression:"item.file"}})]}}],null,false,2948522360)})],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }