




































































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import FileInput from "@/components/FileInput.vue";
import SubArrayForm from "../SubArrayForm.vue";
import EntitySelect from "../EntitySelect.vue";
import { Guid } from "guid-typescript";

@Component({
  components: {
    FormContainer,
    SubArrayForm,
    Loader,
    FileInput,
    EntitySelect,
  },
})
export default class ConsentForm extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public name!: string;

  public provider!: DataProvider<any>;

  public crud!: ICrudClient<any>;

  private item: any = null;
  private text!: string;
  private files: any[] = [];
  created() {
    this.provider = this.$service.providers.consentForms;
    this.crud = this.$service.crud.consentForms;
    this.text = "";
  }
  async mounted() {
    if (this.id === "new") {
      this.item = {
        id: Guid.create().toString(),
        name: "",
        questionnaire: null,
        questionnaireId: null,
        files: [],
      };
    }
  }
  @Watch("item", { immediate: true })
  onItemChanged() {
    this.files = this.item
      ? this.item.files.map((f: any) => ({ file: f }))
      : [];
  }

  @Watch("files", { immediate: true })
  onFilesChanged() {
    if (!this.item) {
      return;
    }
    this.item.files = this.files.map((f: any) => f.file);
  }
  validate(i: any) {
    return i && !!i.file.name;
  }
}
